<template>
  <div style="height: auto; background-color: #fff; border: 10px solid #edeff2">
    <a-spin :spinning="spinning">
      <div class="CCE">
        <div :style="{marginTop: '6px', width: '75px', color: variables.topTitleColor,}">会议分类：</div>
        <div style="height: auto">
          <a-button
            :class="{ liBackground: provincesStyle === 0, textAlign: 'center' ,color:variables.topNameColor}"
            size="small"
            @click="provinces(0)"
            style="border: none; box-shadow: none"
            >全部</a-button
          >
          <a-button
            v-for="(item, index) in liveClassifyList"
            :key="item.id"
            style="margin: 5px; border: none; box-shadow: none"
            size="small"
            :class="{
              liBackground: provincesStyle === index + 1,
              textAlign: 'center',
              color:variables.topNameColor
            }"
            id="provincesBtn"
            @click="provinces(index + 1, item.id)"
            >{{ item.title }}</a-button
          >
        </div>
      </div>
      <div class="live-top">
        <div class="live-top-div">
          <div class="live-top-input">
            <div :style="{color: variables.topTitleColor,}">会议名称：</div>
            <a-input
                v-model="titleData"
                class="input"
                placeholder="会议名称关键词"
            />
          </div>
          <div class="live-top-input">
            <div :style="{color: variables.topTitleColor,width:'70px'}">会议日期：</div>
            <a-range-picker
                style="width: 250px"
                v-model="year"
                @change="onChange"
                :allowClear="false"
                @ok="timeChangeOk"
            >
<!--              <template slot="renderExtraFooter"> extra footer </template>-->
            </a-range-picker>
            <!--        <a-range-picker @change="onChange" />-->
          </div>
          <div class="live-top-input">
            <div :style="{color: variables.topTitleColor,width:'70px'}">会议状态：</div>
<!--            <a-select-->
<!--                v-model="liveStatus"-->
<!--                class="input"-->
<!--                @change="radioChange"-->
<!--            >-->
<!--              <a-select-option value=""> 全部 </a-select-option>-->
<!--              <a-select-option value="0"> 未开始 </a-select-option>-->
<!--              <a-select-option value="1"> 直播中 </a-select-option>-->
<!--              <a-select-option value="2"> 已结束 </a-select-option>-->
<!--            </a-select>-->
<!--            <a-radio-group v-model="liveStatus" button-style="solid" @change="radioChange">-->
            <a-radio-group v-model="liveStatus" button-style="solid" >
              <a-radio-button value="0" @click="radioClick('0')">
                未开始
              </a-radio-button>
              <a-radio-button value="1" @click="radioClick('1')">
                直播中
              </a-radio-button>
              <a-radio-button value="2" @click="radioClick('2')">
                已结束
              </a-radio-button>
            </a-radio-group>
          </div>
          <div class="live-top-input">
            <a-button @click="seachBtn" type="primary">查询</a-button>
            <a-button @click="resetBtn" style="margin-left: 10px">重置</a-button>
          </div>
        </div>
      </div>
      <div style="border-bottom: 1px solid #edeff2;margin-bottom: 15px">
        <div class="titleCard">
          <div :style="{fontWeight: 600,color:variables.topNameColor}">会议列表</div>
          <a-button @click="addTopBtn" type="primary" style="width: 100px;margin-top: 9px">
            + 新建会议</a-button
          >
        </div>
      </div>
      <div v-if="dataList.length === 0"><NullDataPrompt message="您还没有会议呢 ~"/></div>
      <div v-else class="card">
        <a-row>
          <a-col :span="innerWidths > 0 && innerWidths < 1920 ? 5 : 4"
              class="colHover" v-for="card in dataList">
            <div class="colCHover" :style="{ width: innerWidths > 0 && innerWidths < 1920 ? '91%' : '89%',margin:'auto',border: '1px solid #f5f5f5'}">
              <div style="position: relative;border-bottom: 1px solid #f5f5f5;">
                <a-tag
                  style="position: absolute; margin: 10px; color: white"
                  :color="
                    card.statusSingle === 0
                      ? '#2db7f5'
                      : card.statusSingle === 1
                      ? '#FFBA69'
                      : card.statusSingle === 2
                      ? '#cccccc'
                      : ''
                  "
                >
                  {{
                    card.statusSingle === 1
                      ? "直播中"
                      : card.statusSingle === 0
                      ? "未开始"
                      : card.statusSingle === 2
                      ? "已结束"
                      : ""
                  }}
                </a-tag>
                <div style="width: 100%;display: flex;justify-content: center">
                  <img style="width: 100%;height: 160px;" :src="card.detailCoverUrl">
                </div>
              </div>
              <div style="margin: 7px">
                <h3 class="titleSty">{{ card.title }}</h3>
<!--                <span style="color: #a0a2a3">{{ card.sortTime }}</span>-->
                <span style="color: #a0a2a3">{{ card.displayTime }}</span>
              </div>
              <div class="btn">
                <div class="fontSty" @click="linkOpen(card)">链接</div>
                <div>|</div>
                <div class="fontSty" @click="functionSet(card)">功能</div>
                <div>|</div>
                <div class="fontSty" @click="restBtn(card)">设置</div>
<!--                <div class="fontSty">-->
<!--                  <a-dropdown>-->
<!--                    <a-->
<!--                      style="color: #b4b3b3"-->
<!--                      class="ant-dropdown-link"-->
<!--                      @click="(e) => e.preventDefault()"-->
<!--                    >-->
<!--                      更多 <a-icon type="down" />-->
<!--                    </a>-->
<!--                    <a-menu slot="overlay">-->
<!--                      <a-menu-item @click="restBtn(card)">-->
<!--                        <a href="javascript:;">编辑</a>-->
<!--                      </a-menu-item>-->
<!--                    </a-menu>-->
<!--                  </a-dropdown>-->
<!--                </div>-->
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div style="width: 100%" v-show="dataList.length">
        <div style="display: flex; justify-content: center">
          <a-pagination
            show-quick-jumper
            :pageSize="innerWidths > 0 && innerWidths < 1920 ? 10 : 12"
            :current="page_no"
            :total="total"
            @change="paginationChange"
          />
        </div>
        <div style="height: 10px"></div>
      </div>
      <!--------------------------------------对话框------------------------>
      <a-drawer
        @close="add_cancel"
        :width="660"
        :body-style="{ paddingBottom: '80px' }"
        :visible="add_visible"
        :title="drawerTitle"
      >
        <a-form-model
          ref="content_list_Form_add"
          :model="add_Form"
          :rules="addFormRules"
          :label-col="labelColAdd"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="name" label="标题">
            <a-input
              v-model="add_Form.name"
              style="width: 300px"
              placeholder="请输入"
            />
          </a-form-model-item>

          <a-form-model-item prop="time" label="会议时间">
            <a-input
              v-model="add_Form.time"
              style="width: 300px"
              placeholder="例：6月21日 08:30～11:00"
            />
          </a-form-model-item>

          <a-form-model-item prop="startTime" label="开始时间">
            <a-date-picker
              style="width: 300px"
              placeholder="请选择"
              @change="StartChange"
              v-model="add_Form.startTime"
              format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
            />
          </a-form-model-item>

          <a-form-model-item
            prop="columId"
            label="会议分类"
            style="width: 600px"
          >
            <a-select
              show-search
              option-filter-prop="children"
              style="width: 300px; float: left"
              class="input"
              v-model="add_Form.columId"
              placeholder="请选择"
            >
              <a-select-option
                v-for="lives in liveClassifyList"
                :value="lives.id"
              >
                {{ lives.title }}
              </a-select-option>
            </a-select>
            <!--              <div>-->
            <a-icon @click="liveClassifyRow" class="iconStY" type="redo" />
            <a-icon @click="loadList" class="iconStY" type="plus-square" />
            <!--              </div>-->
          </a-form-model-item>

          <a-form-model-item label="详情封面图" prop="relayImg">
            <div style="position: relative;width: 288px;">
              <div class="upImgModel" @click="openImgModel('relayImg')">
                <div class="content" v-if="!add_Form.relayImg">
                  <div class="addModel">+</div>
                  <div>
                    上传
                  </div>
                </div>
                <div>
                  <img style="width: 280px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left: 3px"
                       :src="add_Form.relayImg" alt="">
                </div>
              </div>
              <a-icon v-if="add_Form.relayImg"
                      @click="()=>{add_Form.relayImg = ''}"
                      class="icon" type="close-circle"/>
            </div>
<!--            <div class="surfacePlot" style="position: relative">-->
<!--              <div-->
<!--                style="position: absolute; width: 100%; text-align: center"-->
<!--                v-show="!add_Form.relayImg"-->
<!--              >-->
<!--                <div style="font-size: 40px; color: #929292">+</div>-->
<!--                <div style="font-size: 20px; color: #929292">上传</div>-->
<!--              </div>-->
<!--              <input-->
<!--                id="surfacePlot"-->
<!--                ref="surfacePlot"-->
<!--                style="-->
<!--                  width: 100%;-->
<!--                  height: 100%;-->
<!--                  opacity: 0;-->
<!--                  z-index: 999999;-->
<!--                  position: absolute;-->
<!--                  top: 0;-->
<!--                "-->
<!--                accept="image/*"-->
<!--                type="file"-->
<!--                @change="surfacePlotBtn"-->
<!--              />-->
<!--              <div class="uploadImg_img">-->
<!--                <img-->
<!--                  v-if="add_Form.relayImg"-->
<!--                  alt=""-->
<!--                  style="-->
<!--                    width: 98%;-->
<!--                    height: 98%;-->
<!--                    border-radius: 5px;-->
<!--                    margin: auto;-->
<!--                    margin-top: 2px;-->
<!--                    background-color: #fff;-->
<!--                  "-->
<!--                  :src="add_Form.relayImg"-->
<!--                />-->
<!--              </div>-->
<!--              <div-->
<!--                style="-->
<!--                  position: absolute;-->
<!--                  top: -8px;-->
<!--                  right: 10px;-->
<!--                  z-index: 99999999;-->
<!--                "-->
<!--              >-->
<!--                <a-icon-->
<!--                  v-show="add_Form.relayImg[0]"-->
<!--                  @click="-->
<!--                    () => {-->
<!--                      add_Form.relayImg = '';-->
<!--                    }-->
<!--                  "-->
<!--                  style="color: #f82c2c; cursor: pointer"-->
<!--                  type="close-circle"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
            <div style="color: #a1a1a1">注:尺寸 640*360，大小不超过500kb</div>
          </a-form-model-item>

          <a-form-model-item label="介绍海报">
            <div style="position: relative;width: 288px;">
              <div class="upImgModel" @click="openImgModel('homeCoverImage')">
                <div class="content" v-if="!add_Form.homeCoverImage">
                  <div class="addModel">+</div>
                  <div>
                    上传
                  </div>
                </div>
                <div>
                  <img style="width: 280px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left: 3px"
                       :src="add_Form.homeCoverImage" alt="">
                </div>
              </div>
              <a-icon v-if="add_Form.homeCoverImage"
                      @click="()=>{add_Form.homeCoverImage = ''}"
                      class="icon" type="close-circle"/>
            </div>
<!--            <div class="bgImg" style="position: relative">-->
<!--              <div-->
<!--                style="position: absolute; width: 100%; text-align: center"-->
<!--                v-show="!add_Form.homeCoverImage"-->
<!--              >-->
<!--                <div-->
<!--                  style="-->
<!--                    font-size: 40px;-->
<!--                    color: #929292;-->
<!--                    margin-bottom: 10px;-->
<!--                    margin-top: 30px;-->
<!--                  "-->
<!--                >-->
<!--                  +-->
<!--                </div>-->
<!--                <div style="font-size: 20px; color: #929292">上传</div>-->
<!--              </div>-->
<!--              <input-->
<!--                id="add_back_id"-->
<!--                ref="add_back_ref"-->
<!--                style="-->
<!--                  width: 100%;-->
<!--                  height: 100%;-->
<!--                  z-index: 999999;-->
<!--                  opacity: 0;-->
<!--                  position: absolute;-->
<!--                  top: 0;-->
<!--                "-->
<!--                accept="image/*"-->
<!--                type="file"-->
<!--                @change="add_back"-->
<!--              />-->
<!--              <div class="uploadImg_img">-->
<!--                <img-->
<!--                  v-if="add_Form.homeCoverImage"-->
<!--                  alt=""-->
<!--                  style="-->
<!--                    width: 98%;-->
<!--                    height: auto;-->
<!--                    border-radius: 5px;-->
<!--                    margin: auto;-->
<!--                    margin-top: 2px;-->
<!--                    background-color: #fff;-->
<!--                  "-->
<!--                  :src="add_Form.homeCoverImage"-->
<!--                />-->
<!--              </div>-->
<!--              <div-->
<!--                style="-->
<!--                  position: absolute;-->
<!--                  top: -8px;-->
<!--                  right: 10px;-->
<!--                  z-index: 99999999;-->
<!--                "-->
<!--              >-->
<!--                <a-icon-->
<!--                  v-show="add_Form.homeCoverImage"-->
<!--                  @click="-->
<!--                    () => {-->
<!--                      add_Form.homeCoverImage = '';-->
<!--                    }-->
<!--                  "-->
<!--                  style="color: #f82c2c; cursor: pointer"-->
<!--                  type="close-circle"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
            <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>
          </a-form-model-item>
        </a-form-model>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button @click="add_cancel"> 取消 </a-button>
          <a-button
            style="margin-left: 10px"
            type="primary"
            @click="add_content_list_Ok"
          >
            确认
          </a-button>
        </div>
      </a-drawer>
      <!-- 新增对话框 -->
      <a-modal
        title="新增"
        :visible="visibleClass"
        @ok="handleOk"
        @cancel="handleCancel"
        style="width: 200px; height: 130px"
      >
        <a-form-model
          :model="form"
          :rules="addRules"
          ref="ruleForm_add"
          style="display: flex"
        >
          <a-form-model-item label="分类标题" prop="name" style="display: flex">
            <a-input
              placeholder="请输入"
              v-model="form.name"
              style="display: flex; margin-top: 5px; width: 220px"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!--   链接   -->
      <LinkPopup />
      <!--  选择体图片    -->
      <pictureDialog
        :visible="visible"
        v-on:closeMain="closeMain"
        v-on:checkList="checkList"
      ></pictureDialog>
    </a-spin>
  </div>
</template>

<script>
import variables from '@/assets/base.scss'
import {
  get_per_OfThe_one_list,
  liveClassify,
  put_per_OfThe_one_list_ofNumber,
  putSwitch,
  upDown,
} from "@/service/MedicalConference";
import { update } from "@/utils/update";
import { subordinate } from "@/utils/subordinateList";
import moment from "moment";
import {
  getTemplateColorPicMeet,
  getTemplateTitle,
  putImgs,
} from "@/service/medicalConference_api";
import {
  delAppPromotion,
  getAppPromotion,
  getColumnVodsList,
  postAppPromotion,
} from "@/service/MedicalConference_y";
import PublicSample from "@/utils/PublicSample";
const columns = [
  {
    title: "会议名称",
    dataIndex: "title",
    key: "title",
    width: "35%",
    ellipsis: true,
  },
  {
    title: "会议地址",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "会议日期",
    dataIndex: "beginDate",
    key: "beginDate",
    width: "20%",
    scopedSlots: { customRender: "beginDate" },
  },
  {
    title: "是否发布",
    dataIndex: "isPublished",
    key: "isPublished",
    scopedSlots: { customRender: "isPublished" },
  },
  {
    title: "平台",
    dataIndex: "domainCode",
    key: "domainCode",
    width: "10%",
    scopedSlots: { customRender: "domainCode" },
  },
  {
    title: "功能设置",
    dataIndex: "functionSet",
    key: "functionSet",
    scopedSlots: { customRender: "functionSet" },
  },
  {
    title: "操作",
    dataIndex: "system",
    key: "system",
    width: "10%",
    scopedSlots: { customRender: "system" },
  },
];
// 在需要生成二维码的文件中引入比如qrCode.vue

import VueQr from 'vue-qr';
import {liveMettingSpecials, liveMettingSpecialsPost, liveMettingSpecialsPut} from "@/service/liveManage";
import LinkPopup from "@/components/LiveRoomFunModule/LinkPopup";
import PictureDialog from "@/components/Material/pictureDialog";
import { AddManagement } from "@/service/columnmanageapi";
import NullDataPrompt from "@/components/NullDataPrompt";
export default {
  components: {
    NullDataPrompt,
    PictureDialog,
    LinkPopup,
    PublicSample,
    VueQr,
  },
  data() {
    return {
      variables,
      innerWidths:null,
      openModelType:null,
      picList:[],
      visible: false,
      propList: {
        visible: false,
        code: null,
      },
      add_visible: false,
      add_Form: {
        name: "",
        time: "",
        startTime: "",
        columId: undefined,
        homeCoverImage: "",
        relayImg: "",
      },
      liveClassifyList: [],
      addFormRules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        time: [{ required: true, message: "请选择", trigger: "change" }],
        startTime: [{ required: true, message: "请选择", trigger: "change" }],
        columId: [{ required: true, message: "请选择", trigger: "change" }],
        relayImg: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      year: [undefined, undefined],
      yearShowOne: false,
      size: "default",
      promotion_visible: false,
      labelColAdd: { span: 6 },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      titleData: "",
      dataList: [],
      columns,
      page_no: 1,
      // page_size: 8,
      total: null,
      dateFormat: "YYYY-MM-DD ",
      spinning: false,
      promotion_form: {
        isBeginImage: 0,
        beginImageDetail: [],
        beginImageTime: null,
        isBannerShow: 0,
        bannerImage: "",
      },
      promotion_rules: {
        beginImageDetail: [
          { required: true, message: "请上传", trigger: "blur" },
        ],
        beginImageTime: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        bannerImage: [{ required: true, message: "请上传", trigger: "blur" }],
      },
      listId: "",
      listMeetingCode: "",
      domainCode: "",
      template_list: [],
      templateListUrl: "",
      optionValue: "",
      yun_subordinate_select: subordinate(),
      promotionsVisible: false,
      appColumns: [
        {
          title: "APP首页",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "状态",
          dataIndex: "is_open",
          key: "is_open",
          scopedSlots: { customRender: "is_open" },
        },
      ],
      appData: [],
      columnListApp: [],
      promotionId: null,
      promotionSpin: false,
      tooltip: "用于首页的直播倒计时",
      tooltipAddress: "显示在转发之后的消息摘要处",
      provincesStyle: 0,
      columId: null,
      liveStatus: "",
      status0:1,
      status1:1,
      status_1:1,
      visibleClass: false,
      form: {},
      addRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      drawerTitle: "",
    };
  },
  created() {
    this.innerWidths = window.innerWidth; //屏幕宽度
    this.getLiveList();
    this.liveClassifyRow();
    //  加载模板列表
    this.getTemList();
  },
  methods: {
    openImgModel(type){
      this.visible = true;
      this.openModelType = type;
    },
    closeMain(val) {
      this.visible = val;
    },
    checkList(val) {
      this.picList = val[0].url;
      let type = this.openModelType;
      if(type === 'relayImg'){ //详情封面图
        this.add_Form.relayImg = this.picList;
      }else if(type === 'homeCoverImage'){ //详情封面图
        this.add_Form.homeCoverImage = this.picList;
      }
    },

    //可以看到src并非url，而是base64字符串。base64转Blob：
    base64ToBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    provinces(index, id) {
      this.provincesStyle = index;
      this.columId = id;
      this.getLiveList();
    },
    openSample(code) {
      // code加上时间防止重复
      this.propList = {
        visible: true,
        code: code + "202304031442",
      };
    },
    //获取会议列表
    async getLiveList() {
      this.spinning = true;
      let data = {
        type: "SINGLE",
        title: this.titleData,
        columnId: this.columId,
        statusSingle: this.liveStatus,
        starTime: this.year[0],
        endTime: this.year[1],
        pageNo: this.page_no,
        pageSize:  this.innerWidths > 0 && this.innerWidths < 1920 ? 10 : 12,
      };
      const response = await liveMettingSpecials(data);
      if (response.code === 0) {
        this.dataList = response.data.rows;
        this.total = response.data.count;
      } else {
        this.$message.warning(response.message);
      }
      this.spinning = false;
    },
    async liveClassifyRow() {
      let data = {
        pageNo: 1,
        pageSize: 9999999,
        type: "meeting",
      };
      const response = await liveClassify(data);
      if (response.code === 0) {
        this.liveClassifyList = response.data;
      } else {
        this.$message.warning(response.message);
      }
      // this.spinning = false
    },
    handleOk() {
      this.$refs.ruleForm_add.validate((valid) => {
        if (valid) {
          let data = {
            title: this.form.name,
            type:'meeting'
          }
          this.postClass(data)
          this.form.name = '';
          this.visibleClass = false;
        }
      });
    },
    loadList() {
      this.visibleClass = true;
    },
    handleCancel() {
      this.visibleClass = false;
    },
    async postClass(data) {
      const response = await AddManagement(data)
      if (response.code === 200) {
        this.$message.success("新增成功！");
      } else {
        this.$message.warning(response.message);
      }
    },
    restBtn(row) {
      this.drawerTitle = "编辑";
      this.listMeetingCode = row.meetingCode; //会议编码
      this.add_visible = true;
      this.add_Form = {
        name: row.title,
        time: row.displayTime,
        startTime: row.sortTime,
        columId: row.columnId,
        relayImg: row.detailCoverUrl,
        homeCoverImage: row.descImageUrl,
      };
    },
    radioChange() {
      this.page_no = 1;
      this.getLiveList();
    },
    radioClick(val){
      if(val === '0'){
        if(this.status0 === 1){
          this.liveStatus = val;
          this.status0++;
        }else {
          this.liveStatus = '';
          this.status0 = 1;
        }
        this.status1 = 1;
        this.status_1 = 1;
      }else if(val === '1'){
        if(this.status1 === 1){
          this.liveStatus = val;
          this.status1++;
        }else {
          this.liveStatus = '';
          this.status1 = 1;
        }
        this.status0 = 1;
        this.status_1 = 1;
      }else if(val === '2'){
        if(this.status_1 === 1){
          this.liveStatus = val;
          this.status_1++;
        }else {
          this.liveStatus = '';
          this.status_1 = 1;
        }
        this.status0 = 1;
        this.status1 = 1;
      }
      this.page_no = 1;
      this.getLiveList();
    },
    seachBtn() {
      this.getLiveList();
    },
    resetBtn() {
      this.provincesStyle = 0;
      this.titleData = "";
      this.columId = "";
      this.liveStatus = "";
      this.page_no = 1;
      this.year = [];
      this.getLiveList();
    },
    paginationChange(page_no, page_size) {
      this.page_no = page_no;
      this.getLiveList();
    },
    functionSet(row) {

      this.$router.push({
        path: "/liveRoom",
        query: { id: row.id, meetingCode: row.meetingCode, type: "SINGLE" },
      });
      this.$store.dispatch("isShowNav", false);
    },
    linkOpen(row) {
      if(row.targetUrl){
        this.$store.dispatch("isShowLink", true);
        let data = {
          //前端观看链接
          targetUrl:row.targetUrl ? row.targetUrl : '',
          //服务器链接
          obsPushIp:row.obsPushIp ? row.obsPushIp : '',
          //串流密钥链接
          obsPushName:row.obsPushName ? row.obsPushName : '',
          //rtmp链接
          pullRtmpIp:row.pullRtmpIp ? row.pullRtmpIp : '',
          //flv链接
          pullFlvIp:row.pullFlvIp ? row.pullFlvIp : '',
          //m3u8链接
          pullM3u8Ip:row.pullM3u8Ip ? row.pullM3u8Ip : '',
        }
        this.$store.dispatch("linkList", data);
      }else {
        this.$message.info("当前链接为空！")
      }
    },

    //公用上移下移
    async upDown(convention_id, action) {
      const response = await upDown(convention_id, action);
      if (response.code === 0) {
        this.$message.success("操作成功！");
        //  刷新
        await this.getLiveList();
      } else {
        this.$message.warning("失败!", response.message);
      }
    },
    upBtn(id) {
      let action = "up";
      this.upDown(id, action);
    },
    downBtn(id) {
      let action = "down";
      this.upDown(id, action);
    },
    async switchBtn(id, isOpen) {
      let data = {
        isPublished: isOpen,
      };
      const response = await putSwitch(data);
      if (response.code === 0) {
        this.spinning = true;
        this.$message.success("操作成功！");
        //  刷新
        await this.getLiveList();
        this.spinning = false;
      } else {
        this.$message.warning("失败!", response.message);
      }
    },
    //获取单个详情
    async getOneList(id) {
      const response = await get_per_OfThe_one_list(id);
      if (response.code === 0) {
        let form = this.promotion_form;
        form.isBeginImage = response.data.isBeginImage;
        if (response.data.beginImageDetail) {
          form.beginImageDetail = response.data.beginImageDetail;
        }
        form.beginImageTime = response.data.beginImageTime;
        form.isBannerShow = response.data.isBannerShow;
        form.bannerImage = response.data.bannerImage;
      } else {
        this.$message.warning("失败!", response.message);
      }
    },
    promotion_Switch_btn(isOpen) {
      this.promotion_form.isBeginImage = isOpen;
    },
    openTwo_Switch_btn(isOpen) {
      this.promotion_form.isBannerShow = isOpen;
    },
    promotion_Btn(id) {
      this.listId = id;
      this.promotion_visible = true;
      this.getOneList(id);
    },
    //修改公用
    async restGong(data) {
      const response = await put_per_OfThe_one_list_ofNumber(this.listId, data);
      if (response.code === 0) {
        this.$message.success("修改成功!");
        //  刷新
        await this.getOneList(this.listId);
      } else {
        this.$message.warning("失败!", response.message);
      }
    },
    promotion_Submit() {
      this.$refs.promotion_ruleForm_form_two.validate((valid) => {
        if (valid) {
          let form = this.promotion_form;
          let data = {
            isBeginImage: form.isBeginImage,
            beginImageTime: form.beginImageTime,
            beginImageDetail: form.beginImageDetail,
            isBannerShow: form.isBannerShow,
            bannerImage: form.bannerImage,
          };
          this.promotion_visible = false;
          this.restGong(data);
          this.promotion_form.isBeginImage = null;
          this.promotion_form.beginImageDetail = [];
          this.promotion_form.beginImageTime = "";
          this.promotion_form.isBannerShow = 0;
          this.promotion_form.bannerImage = "";
        } else {
          this.$message.warning("有空~~");
          return false;
        }
      });
    },
    async promotion_upPosterCover() {
      let inputDOM = this.$refs.promotion_descImageUrl.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM);
        if (image.code === 0) {
          // this.promotion_form.beginImageDetail[0] = image.data.url
          this.$set(this.promotion_form.beginImageDetail, 0, image.data.url);
          this.$message.success("上传成功");
        } else {
          this.$message.warning("上传失败");
        }
      } else {
        this.$message.warning("请上传小于1M的图片！");
      }
      document.getElementById("promotion_descImageUrl_id").value = null;
    },
    async promotion_upPosterCover_beginImage() {
      let inputDOM = this.$refs.promotion_descImageUrl_beginImage_two.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM);
        if (image.code === 0) {
          this.promotion_form.bannerImage = image.data.url;
          this.$message.success("上传成功");
        } else {
          this.$message.warning("上传失败");
        }
      } else {
        this.$message.warning("请上传小于1M的图片！");
      }
      document.getElementById("promotion_descImageUrl_beginImageId").value =
        null;
    },
    colorAdd(e) {
      this.add_Form.mainColor = e.target.value;
    },
    moment,
    addTopBtn() {
      this.drawerTitle = "新增";
      this.listMeetingCode = "";
      this.add_visible = true;
    },
    StartChange(date, dateString) {
      this.add_Form.startTime = dateString;
    },
    templateChange() {
      console.log(this.add_Form.template);
    },
    async add_upPosterCover() {
      let form = this.add_Form;
      let _this = this;
      let inputDOM = this.$refs.add_upPosterCover_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 2097152) {
        //判断图片尺寸
        if (inputDOM) {
          var reader = new FileReader();
          reader.onload = function (event) {
            var base64 = event.target.result;
            var img = document.createElement("img");
            img.src = base64;
            img.onload = async function () {
              if (img.width <= 1920) {
                const image = await update(inputDOM);
                if (image.code === 0) {
                  // this.add_Form.coverPics[0] = image.data.url
                  _this.$set(form.coverPics, 0, image.data.url);
                  _this.$message.success("上传成功");
                } else {
                  _this.$message.warning("上传失败");
                }
              } else {
                _this.$message.warning(
                  "注:建议尺寸1920px*1080px，宽度不能超过1920px !"
                );
              }
            };
          };
          reader.readAsDataURL(inputDOM);
        }
      } else {
        this.$message.warning("请上传小于2M的图片！");
      }
      document.getElementById("add_upPosterCoverId").value = null;
    },
    delCover() {
      this.add_Form.coverPics = [];
    },
    async add_back() {
      let form = this.add_Form;
      let _this = this;
      let inputDOM = this.$refs.add_back_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        //判断图片尺寸
        if (inputDOM) {
          var reader = new FileReader();
          reader.onload = function (event) {
            var base64 = event.target.result;
            var img = document.createElement("img");
            img.src = base64;
            img.onload = async function () {
              if (img.width <= 750) {
                const image = await update(inputDOM);
                if (image.code === 0) {
                  _this.add_Form.homeCoverImage = image.data.url;
                  _this.$message.success("上传成功");
                } else {
                  _this.$message.warning("上传失败");
                }
              } else {
                _this.$message.warning("注:宽度不能超过750px !");
              }
            };
          };
          reader.readAsDataURL(inputDOM);
        }
      } else {
        this.$message.warning("请上传小于1M的图片！");
      }
      document.getElementById("add_back_id").value = null;
    },
    async delImg(type) {
      if (type === "add_Form") {
        this.add_Form.homeCoverImage = "";
      } else if (type === "list_Form") {
        //修改的删除是将list中的图片删除
        let id = this.listId * 1;
        const image = await putImgs(id);
        if (image.code === 200) {
          this.$message.success("图片删除成功~");
        } else {
          this.$message.warning(image.message);
        }
      }
    },
    add_content_list_Ok() {
      this.$refs.content_list_Form_add.validate((valid) => {
        if (valid) {
          let form = this.add_Form;
          if (this.listMeetingCode) {
            const dataRest = {
              title: form.name,
              displayTime: form.time,
              sortTime: form.startTime,
              detailCoverUrl: form.relayImg,
              descImageUrl: form.homeCoverImage,
              columnId: form.columId,
              type: "SINGLE",
            };
            this.putLive(this.listMeetingCode, dataRest);
          } else {
            const data = {
              title: form.name,
              displayTime: form.time,
              sortTime: form.startTime,
              type: "SINGLE", //写死
              detailCoverUrl: form.relayImg,
              descImageUrl: form.homeCoverImage,
              columnId: form.columId,
            };
            this.postLive(data);
          }
          this.add_cancel();
        } else {
          return false;
        }
      });
    },
    add_cancel() {
      //  关闭对话框
      this.add_visible = false;
      this.add_Form = {
        name: "",
        time: "",
        startTime: "",
        columId: undefined,
        homeCoverImage: "",
        relayImg: "",
      };
      this.listId = "";
      this.$refs.content_list_Form_add.resetFields();
    },
    //  获取列表 --- 模板列表
    async getTemList() {
      const Info = await getTemplateTitle();
      if (Info.code === 0) {
        // this.template_list = response.data
        for (let i = 0; i < Info.data.length; i++) {
          if (Info.data[i].isOpen === 1) {
            this.template_list.push({
              id: Info.data[i].id,
              isBanner: Info.data[i].isBanner,
              isBgimg: Info.data[i].isBgimg,
              isNotice: Info.data[i].isNotice,
              isOpen: Info.data[i].isOpen,
              name: Info.data[i].name,
            });
          }
        }
      }
    },
    // 获取列表 --- 模板列表中的url
    async getTemList_url(id) {
      const response = await getTemplateColorPicMeet(id);
      if (response.code === 0) {
        this.templateListUrl = response.data.url;
      }
    },
    //新增直播会议列表
    async postLive(data) {
      const response = await liveMettingSpecialsPost(data);
      if (response.code === 0) {
        this.$message.success("新增成功~");
        //  刷新列表
        await this.getLiveList();
      } else {
        this.$message.warning(response.message);
      }
    },
    //修改直播会议列表
    async putLive(listMeetingCode, data) {
      const response = await liveMettingSpecialsPut(listMeetingCode, data);
      if (response.code === 0) {
        this.$message.success("修改成功~");
        await this.getLiveList();
      } else {
        this.$message.warning(response.message);
      }
    },
    selectTem(id) {
      //  通过选择的id获取url的后半截
      this.getTemList_url(id);
    },
    promotionsClose() {
      this.promotionsVisible = false;
      this.appData = [];
      this.promotionId = null;
    },
    async AppListSwitch(isOpen, row) {
      //推广
      if (isOpen) {
        let data = [
          {
            contentId: this.promotionId, //内容id
            contentType: "LIVE_METTING", //一级内容
            columnCode: row.code, //栏目
            subContentType: "CONVENTION", //子级内容
          },
        ];
        await this.postAppSwitch(data, row);
      } else {
        //关闭推广
        await this.delAppSwitch(this.promotionId, row.code, "app", row);
      }
    },
    async delAppSwitch(id, code, type, row) {
      let data = {
        code: code,
        type: type,
      };
      const response = await delAppPromotion(id, data);
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a, index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a);
            return;
          }
        });
        this.$message.success("关闭成功~");
      } else {
        this.$message.warning(response.message);
      }
    },
    async postAppSwitch(data, row) {
      const response = await postAppPromotion(data);
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a, index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a);
            return;
          }
        });
        this.$message.success("推广成功~");
      } else {
        this.$message.warning(response.message);
      }
    },
    //获取栏目
    async getColumnList(id) {
      this.promotionSpin = true;
      this.appData = [];
      const response = await getColumnVodsList();
      if (response.code === 0) {
        this.appData = response.data;
        await this.getAppList(id);
      } else {
        this.$message.warning(response.message);
      }
      this.promotionSpin = false;
    },
    async promotions(list) {
      this.promotionId = list.id;
      this.getColumnList(list.id);
      this.promotionsVisible = true;
    },
    async getAppList(id) {
      const response = await getAppPromotion(id);
      if (response.code === 0) {
        this.columnListApp = response.data;
        //对比
        let app = this.appData;
        let columnList = this.columnListApp;
        app.forEach((a, index) => {
          a.is_open = columnList.some((b) => {
            return b.code === a.code;
          });
          this.$set(app, index, a);
        });
      } else {
        this.$message.warning(response.message);
      }
    },
    onChange(date, dateString) {
      this.year = dateString;
    },
    timeChangeOk() {
      this.getLiveList();
    },
    async surfacePlotBtn() {
      let inputDOM = this.$refs.surfacePlot.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM);
        if (image.code === 0) {
          this.add_Form.relayImg = image.data.url;
          this.$message.success("上传成功");
        } else {
          this.$message.warning("上传失败");
        }
      } else {
        this.$message.warning("请上传小于500kb的图片！");
      }
      document.getElementById("surfacePlot").value = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.ant-col-4 {
  width: 16.666666%;
}
.ant-col-5 {
  //width: 20.8333333%;
  width: 20%;
}
.liBackground {
  background: #1890ff !important;
  color: white;
}
.CCE {
  min-height: 60px;
  width: $space_div;
  margin: auto;
  padding-top: 15px;
  border-bottom: 0.5px dashed #d7d7d7;
  display: flex;
}
.live-top {
  height: 75px;
  border-bottom: 10px solid #edeff2;
  padding-top: 15px;
  .live-top-div{
    width: $space_div;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .live-top-input {
    display: flex;
    height: 35px;
    line-height: 35px;
    .input {
      width: 200px;
      //margin-left: 5px;
    }
  }
}
.titleCard{
  display: flex;
  justify-content: space-between;
  width: $space_div;
  margin: auto;
  height: 50px;
  line-height: 50px;
}
.uploadImg {
  border: #dddddd 2px dashed;
  width: 300px;
  height: 169px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.bgImg {
  border: #dddddd 2px dashed;
  width: 213px;
  min-height: 213px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.surfacePlot {
  border: #dddddd 2px dashed;
  width: 213px;
  height: 120px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.uploadImg_img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
//.upload_poster{
//  border: #DDDDDD 2px dashed;
//  width:110px;
//  min-height: 120px;
//  margin-left: 20px;
//  text-align: center;
//}
//.upload_posterBg{
//  border: #DDDDDD 2px dashed;
//  width:120px;
//  height: auto;
//  margin-left: 20px;
//  text-align: center;
//}
//.upload_posterTwo{
//  border: #DDDDDD 2px dashed;
//  width:240px;
//  height: 135px;
//  margin-left: 20px;
//  text-align: center;
//}
//.upload_poster_beginImage{
//  border: #DDDDDD 2px dashed;
//  width:192px;
//  height: 52px;
//  min-height: 120px;
//  margin-left: 20px;
//  text-align: center;
//}
.card {
  min-height: 580px;
  width: 100%;
  .colHover{
    margin-bottom: 2%;
  }
  .colCHover:hover{
    box-shadow: 1px 1px 15px #d7d7d7;
  }
  .btn {
    background-color: #f7f9fa;
    color: #b4b3b3;
    height: 50px;
    line-height: 50px;
    display: flex;
    cursor: pointer;
    justify-content: space-around;
    .fontSty:hover {
      color: #45a5e6;
    }
  }
}
.titleSty {
  font-weight: 600;
  width: 100%;
  height: 25px;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
}
.linkModal {
  display: flex;
  height: 50px;
  line-height: 50px;
  .title {
    width: 120px;
    text-align: right;
    margin-right: 10px;
  }
}
.codeLink {
  display: flex;
  margin-top: 20px;
  .title {
    width: 120px;
    text-align: right;
    margin-right: 10px;
  }
  .icon {
    color: #45a5e6;
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.iconStY {
  color: #45a5e6;
  font-size: 20px;
  margin-left: 5px;
  margin-top: 3px;
  float: left;
}
.upImgModel{
  width: 288px;
  min-height: 162px;
  text-align: center;
  background-color: #fcfcfc;
  border: #DDDDDD 2px dashed;
  border-radius: 10px;
  .content{
    font-size: 20px;
    text-align: center;
    width: 100%;
    margin-top: 50px;
    color: #929292;
    .addModel{
      font-size: 30px;
    }
  }
}
.icon{
  position: absolute;
  color: red;
  right: 8px;
  top:8px;
  z-index: 99999999999999;
}
</style>
